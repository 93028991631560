@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #cbd5e0;
}

button, [role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-opacity: 1;
  border-color: rgba(237, 242, 247, var(--tw-border-opacity));
}

* {
  font-family: Inter, sans-serif;
}

th, td {
  padding: .5rem;
}

th {
  font-weight: 400;
}

blockquote {
  quotes: "“""”";
}

blockquote.before-quote:before {
  --tw-text-opacity: 1;
  color: rgba(79, 209, 197, var(--tw-text-opacity));
  content: open-quote;
  vertical-align: -.4em;
  margin-right: .25em;
  font-size: 4em;
  line-height: .1em;
}

blockquote.after-quote:after {
  --tw-text-opacity: 1;
  color: rgba(79, 209, 197, var(--tw-text-opacity));
  content: close-quote;
  vertical-align: -.4em;
  margin-left: .2em;
  font-size: 4em;
  line-height: .1em;
}

.main-grid {
  grid-template: "controls"
                 "req-head" 3rem
                 "requests"
                 "off-head" 3rem
                 "offers"
                 / 1fr;
  row-gap: .5rem;
  display: grid;
}

@media (min-width: 640px) {
  .main-grid {
    grid-template: "controls controls"
                   "req-head off-head" 3rem
                   "requests offers" calc(100vh - 6rem)
                   / 1fr 1fr;
    gap: 0 1rem;
  }
}

@media (min-width: 1024px) {
  .main-grid {
    grid-template: "req-head off-head controls" 3rem
                   "requests offers controls" 1fr
                   / minmax(360px, 420px) minmax(360px, 420px) minmax(240px, 400px);
  }
}

@media (min-width: 1280px) {
  .main-grid {
    grid-template: "req-head off-head controls" 3rem
                   "requests offers controls" 1fr
                   / 420px 420px 1fr;
  }
}

.req-head {
  grid-area: req-head;
}

.off-head {
  grid-area: off-head;
}

.requests {
  grid-area: requests;
}

.offers {
  grid-area: offers;
}

.controls {
  grid-area: controls;
}

.btn {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
  border-width: 1px;
  border-color: #0000;
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  padding: .75rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.btn:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.btn:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(160, 174, 192, var(--tw-bg-opacity)) !important;
}

.btn-secondary {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 178, 172, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 151, 149, var(--tw-bg-opacity));
}

.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.expand-btn {
  height: 2rem;
  width: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgba(230, 255, 250, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(56, 178, 172, var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  padding: .25rem .5rem;
}

.expand-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 251, 244, var(--tw-bg-opacity));
}

.pill {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 1px;
  border-color: #0000;
  border-radius: 9999px;
  padding: .25rem .5rem;
  font-weight: 500;
}

.form-radio {
  color-adjust: exact;
  user-select: none;
  height: 1rem;
  width: 1rem;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-origin: border-box;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
  border-radius: 9999px;
  flex-shrink: 0;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.form-radio:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

.form-radio:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  outline: none;
  box-shadow: 0 0 0 3px #6770f380;
}

.form-checkbox {
  color-adjust: exact;
  user-select: none;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-origin: border-box;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
  border-radius: .25rem;
  flex-shrink: 0;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.form-checkbox:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

.form-checkbox:focus {
  border-color: #a4cafe;
  outline: none;
  box-shadow: 0 0 0 3px #a4cafe73;
}

.form-checkbox:checked:focus {
  border-color: #0000;
}

.text-input {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  padding: .25rem .5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-input:focus {
  --tw-border-opacity: 1;
  border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.text-input:invalid:not([value=""]) {
  --tw-border-opacity: 1;
  border-color: rgba(252, 129, 129, var(--tw-border-opacity));
}

.form-description {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .375rem;
  padding: .25rem .5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.form-description:focus {
  --tw-border-opacity: 1;
  border-color: rgba(144, 205, 244, var(--tw-border-opacity));
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline: 2px solid #0000;
}

.react-select__control, .react-select__control--is-focused {
  --tw-border-opacity: 1 !important;
  border-width: 1px !important;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity)) !important;
  box-shadow: none !important;
  border-radius: .375rem !important;
  outline: none !important;
}

.react-select__control--is-focused {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.percentage {
  height: 3rem;
  width: 3rem;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-rotate: -90deg;
  stroke: currentColor;
  fill: none;
  stroke-width: 12px;
  stroke-linecap: round;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.top-auto {
  top: auto;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-auto {
  margin-right: auto;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-auto {
  height: auto;
}

.h-content {
  height: calc(100vh - 3rem);
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-content {
  max-height: calc(100vh - 3rem);
}

.min-h-6 {
  min-height: 1.5rem;
}

.min-h-content {
  min-height: calc(100vh - 3rem);
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-16 {
  width: 4rem;
}

.w-48 {
  width: 12rem;
}

.w-sm {
  width: 24rem;
}

.w-full {
  width: 100%;
}

.max-w-36 {
  max-width: 9rem;
}

.max-w-64 {
  max-width: 16rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-100vw {
  max-width: 100vw;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-between {
  justify-content: space-between;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-none {
  border-style: none;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 178, 178, var(--tw-border-opacity));
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 211, 141, var(--tw-border-opacity));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(250, 240, 137, var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(154, 230, 180, var(--tw-border-opacity));
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 205, 244, var(--tw-border-opacity));
}

.border-primary-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}

.border-secondary-300 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 230, 217, var(--tw-border-opacity));
}

.border-secondary-400 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 209, 197, var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 245, var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 129, 129, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 101, 101, var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 250, 240, var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 240, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 255, 244, var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(104, 211, 145, var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.bg-primary-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.bg-secondary-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 230, 217, var(--tw-bg-opacity));
}

.bg-secondary-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 209, 197, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 101, 101, var(--tw-bg-opacity));
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 62, 62, var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 187, 120, var(--tw-bg-opacity));
}

.hover\:bg-primary-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.hover\:bg-secondary-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 178, 172, var(--tw-bg-opacity));
}

.focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-px {
  padding: 1px;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.font-serif {
  font-family: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(254, 178, 178, var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(252, 129, 129, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 101, 101, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(229, 62, 62, var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(237, 137, 54, var(--tw-text-opacity));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgba(221, 107, 32, var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 201, 75, var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(214, 158, 46, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(72, 187, 120, var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(56, 161, 105, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(66, 153, 225, var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-text-opacity));
}

.text-primary-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.text-secondary-400 {
  --tw-text-opacity: 1;
  color: rgba(79, 209, 197, var(--tw-text-opacity));
}

.text-secondary-500 {
  --tw-text-opacity: 1;
  color: rgba(56, 178, 172, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 101, 101, var(--tw-text-opacity));
}

.hover\:text-secondary-500:hover {
  --tw-text-opacity: 1;
  color: rgba(56, 178, 172, var(--tw-text-opacity));
}

.focus\:text-secondary-700:focus {
  --tw-text-opacity: 1;
  color: rgba(44, 122, 123, var(--tw-text-opacity));
}

*, :before, :after {
  --tw-shadow: 0 0 #0000;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f, 0 -1px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f, 0 -1px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow:focus {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

*, :before, :after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #4299e180;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.filter {
  --tw-blur: var(--tw-empty, );
  --tw-brightness: var(--tw-empty, );
  --tw-contrast: var(--tw-empty, );
  --tw-grayscale: var(--tw-empty, );
  --tw-hue-rotate: var(--tw-empty, );
  --tw-invert: var(--tw-empty, );
  --tw-saturate: var(--tw-empty, );
  --tw-sepia: var(--tw-empty, );
  --tw-drop-shadow: var(--tw-empty, );
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.throb {
  animation: 1s linear infinite throb;
}

.striped:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

@keyframes throb {
  40% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1);
  }

  85% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@media (min-width: 640px) {
  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:block {
    display: block;
  }

  .sm\:h-full {
    height: 100%;
  }

  .sm\:w-20 {
    width: 5rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:pr-2 {
    padding-right: .5rem;
  }

  .sm\:pl-2 {
    padding-left: .5rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 768px) {
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:p-2 {
    padding: .5rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:pl-2 {
    padding-left: .5rem;
  }

  .md\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1280px) {
  .xl\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

/*# sourceMappingURL=index.e550adc2.css.map */
